<template>
  <div id="app">
    <!-- <div style="position: fixed;left: 0px;bottom: 0px; height: fit-content; width: fit-content; z-index: 1;">
			<RadioGroup v-model="userIdentity" @on-change="radio">
				<Radio label="visitor">
					<span>visitor</span>
				</Radio>
				<Radio label="user">
					<span>user</span>
				</Radio>
				<Radio label="member">
					<span>member</span>
				</Radio>
			</RadioGroup>
		</div> -->
    <Layout style="height: 100%;">
			<myHeader />

      <Content style="background-color: #fff">
        <router-view></router-view>
      </Content>
    </Layout>
  </div>
</template>

<script>
import myHeader from './components/myHeader.vue'
export default {
  name: "app",
	components: {
		myHeader
	},
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,
    };
  },
  watch: {
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.screenWidth);
          that.timer = false;
        }, 400);
      }
    },
  },
};
</script>

<style>
body {
  height: 100vh;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
	min-width: 800px;
  height: 100%;
}
.layout {
  border: 1px solid #d7dde4;
  background: #f5f7f9;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}
</style>
