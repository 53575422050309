import fetch from '@/utils/fetch'

const contribution = {
  state: {
  },

  mutations: {
  },

  getters: {
  },

  actions: {
    getProjectContribution: async ({ state, dispatch, commit }, projectId) => {
      let url = `/contribution/findByProject`
      const res = await fetch.post(url, projectId, {
        headers:{
          'Content-Type': 'application/json'
        }
      })
      return res
    }
  }
}

export default contribution
