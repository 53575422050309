import Vue from 'vue'
import Axios from 'axios'
import App from './App.vue'
import qs from 'qs'
import router from './router'
import '@/utils/routerGuard'
import store from './store'
import './plugins/iview.js'
import '../my-theme/index.less'
import VueCookies from 'vue-cookies'
import 'wowjs/css/libs/animate.css';
import ViewUI from 'view-design';
Vue.use(ViewUI);
Vue.use(VueCookies);

ViewUI.LoadingBar.config({
  color: '#2d8cf0',
  failedColor: '#f0ad4e',
  height: 3
});

Vue.prototype.$axios = Axios;
// Axios.defaults.baseURL = 'http://localhost:8889';
Axios.defaults.baseURL = "https://www.blocksop.com:8889";
Vue.prototype.$qs = qs;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),

}).$mount('#app')

