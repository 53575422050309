<template>
  <div class="header-container">
    <div class="header-left">
      <router-link to="/">
        <img class="header-left-logo" src="../assets/logo.png"/>
      </router-link>
    </div>
    <div class="header-middle">
      <div class="header-middle-input">
        <Input
          search
          placeholder=" Search or jump to..."
          @on-search="inputChange"
        />
      </div>
      <div class="header-middle-button">
        <Button ghost type="text" style="color: #fff" icon="ios-keypad" to="/projectList">
          All Projects
        </Button>
        <Button ghost type="text" style="color: #fff" icon="ios-navigate" to="/questionnaire">
          Questionnaire
        </Button>
      </div>
    </div>
    <div class="header-right">
      <div v-if="!isLogin">
        <Button ghost style="color: #fff" to="/Register">Register</Button>
        <Button ghost style="margin-left: 10px; color: #fff" :to="`/Login?redirect=${$route.fullPath}`">Sign in</Button>
      </div>
      <div v-else>
        <Avatar :src="$store.getters.getUserPic || userPic" size="32" />
        <Button type="text" ghost style="color: #fff" to="/user">{{ $store.getters.getUserName }}</Button>
        <Dropdown trigger="click" placement="bottom-end">
          <Icon class="header-right-deviation" type="md-arrow-dropdown" size="24"/>
          <DropdownMenu slot="list">
            <DropdownItem>
              <router-link to ="/create"> New Project </router-link>
            </DropdownItem>
            <DropdownItem>
              <router-link to ="/projectList"> Join Project </router-link>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <Badge dot :count="messageCount" >
          <a class="header-right-deviation" href="/#/notifications" ><Icon type="ios-notifications-outline" size="24" color="white" /></a>
        </Badge>
        <Button ghost style="margin-left:10px; color: #fff;" @click="exitLogin">Exit</Button>
      </div>
    </div>
  </div>
</template>

<script>
import { removeUserId } from "@/utils/auth"
export default {
  data () {
    return {
      userName: 'username',
      userPic: 'https://blocksop.com/ownerbased/record/3d2aef28cf284456ba9a7f52fff6f91a.png',
      messageCount: 0,
    }
  },
  mounted() {
    this.getUserInfo();
  },
  computed: {
    isLogin() {
      return this.$store.getters.getIsLogin
    }
  },
  watch: {
    isLogin(newVal, oldVal) {
      this.getUserInfo()
    }
  },
  methods: {
    getUserInfo () {
      if (!this.isLogin) return
      let Tself = this
      this.$store.dispatch('findUserById', this.$store.getters.getUserId).then((response) => {
        let uf = response.data.data.items;
        Tself.$store.commit('setUserInfo', {
            userName: uf.username,
            userPic: uf.faceUrl
        })
      }).catch((error) => {
        console.log(error);
        this.$Message.error(error.message)
      })
      this.$axios({
        headers: {
          "Content-Type": "application/json",
        },
        method: "post",
        url: "/message/hasUnRead",
        data: this.$store.getters.getUserId,
      }).then(res => {
        if (res.data.data.items) {
          Tself.messageCount = 1;
        } else {
          Tself.messageCount = 0;
        }
      }).catch(function (error) {
        console.log(error);
      })
    },
    exitLogin() {
      this.$cookies.remove("user_id");
      this.$cookies.remove("user_name");
      removeUserId()
      this.$store.commit('setLogout')
      this.getUserInfo()
      this.$router.push('Login')
    },
    inputChange(val) {
      this.$router.push(`/projectList?input=${val}`)
    },
  }
}
</script>

<style lang="less">
.header-container {
  box-sizing: border-box;
  height: 60px;
  width: 100%;
  background-color: #e9ab01;
  display: flex;
  align-items: center;
  color: #fff;
  
  .header-left {
    box-sizing: border-box;
    padding: 5px;
    height: 60px;
    flex: 1 0 240px;
    &-logo {
      height: 100%;
    }
  }
  .header-middle {
    flex: 5 1 480px;
    height: 60px;
    display: flex;
    align-items: center;
    &-input {
      flex: 1 1 240px;
    }
    &-button {
      cursor: pointer;
      flex: 1 0 270px;
    }
  }
  .header-right {
    flex: 1 0 240px;
    height: 60px;
    display: flex;
    align-items: center;
    &-button {
      width: fit-content;
      padding: 0 8px;
    }
    &-deviation {
      cursor: pointer;
      position: relative;
      top: 4px;
    }
  }
}
</style>