import fetch from '@/utils/fetch'

const user = {
  state: {
    isLogin: false,
    userId: '',
    userName: '',
    userPic: '',
  },

  mutations: {
    setLogin: (state, userId) => {
      if (state.userId) return
      state.isLogin = true
      state.userId = userId
    },
    setLogout: (state) => {
      state.isLogin = false
      state.userId = ''
      state.userName = ''
      state.userPic = ''
    },
    setUserInfo: (state, data) => {
      state.userName = data.userName
      state.userPic = data.userPic
    },
  },

  getters: {
    getIsLogin: state => state.isLogin,
    getUserId: state => state.userId,
    getUserName: state => state.userName,
    getUserPic: state => state.userPic
  },

  actions: {
    findUserById: async ({ state, dispatch, commit }, userId) => {
      let url = `user/findById`
      const res = await fetch.post(url, userId, {
        headers:{
          'Content-Type': 'application/json'
        }
      })
      return res
    }
  }
}

export default user
