import Vue from 'vue'
import Vuex from 'vuex'
import file from './modules/file'
import pit from './modules/pit'
import user from './modules/user'
import contribution from './modules/contribution'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    file,
    pit,
    user,
    contribution
  }
})
