import Vue from 'vue'
import VueRouter from 'vue-router'
// import projectDetail from '../views/projectDetail.vue'
// import code from '../components/code.vue'
// import issues from '../components/issues.vue'
// import pullRequests from '../components/pullRequests.vue'
// import user from '../views/user.vue'
// import userRepositories from "../components/userRepositories.vue";
// import userHome from "../components/userHome.vue";
// import userPackages from "../components/userPackages";
// import userProjects from "../components/userProjects";
// import login from "../views/login";
// import signIn from "../views/signIn";
// import createProject from "../components/createProject";
// import functionPage from "../components/function.vue"
// import projectContribution from "../components/projectContribution";
// import createJoin from "../views/createJoin";
// import checkJoin from "../views/checkJoin";
// import checkJoinList from "../components/checkJoinList";
// import checkJoinMember from "../components/checkJoinMember";
// import checkJoinInfo from "../components/checkJoinInfo";
// import createJoin3 from "../components/createJoin3";
// import createJoin2 from "../components/createJoin2";
// import createJoin1 from "../components/createJoin1";
import home from "../views/home.vue";
// import projectList from "../views/projectList.vue"
// import notifications from "../views/notifications.vue"
// import payment from "../views/payment.vue"
// import projectFurtherInfo from "../views/projectFurtherInfo";
// import checkFurtherInfo from "../components/checkFurtherInfo";
// import checkFurtherList from "../components/checkFurtherList";
// import projectEdit from "../views/projectEdit";
// import projectVersion from "../components/ProjectVersion";
// import projectInfoList from "../components/ProjectInfoList";
// import projectInformationEdit from "../components/ProjectInformationEdit";
Vue.use(VueRouter)


/**
 * 因vue-router升级带来的问题
 * 使用router.push时，若目标路由被守卫拦截，定位到其他路由
 * 插件会认为是错误，但功能并没有问题，此举屏蔽控制台报错
 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}

const routes = [
	{
		path: '/',
		name: 'home',
		component: home,
	},
	{
		path: '/projectList',
		name: 'projectList',
		// component: projectList,
		component: resolve => require(["../views/projectList.vue"], resolve)
	},
	{
		path: '/questionnaire',
		name: 'questionnaire',
		component: resolve => require(["../views/questionnaire.vue"], resolve)
	},
	{
		path: '/withdrawInfo',
		name: 'withdrawInfo',
		component: resolve => require(["../views/withdrawInfo.vue"], resolve)
	},
	{
		path: '/payment',
		name: 'payment',
		// component: payment,
		meta: {
			needLogin: true
		},
		component: resolve => require(["../views/payment.vue"], resolve)
	},
	{
		path: '/checkJoinArbitration',
		name: 'checkJoinArbitration',
		// component: payment,
		component: resolve => require(["../views/checkJoinArbitration.vue"], resolve)
	},
	{
		path: '/exchangeCode',
		name: 'exchangeCode',
		// component: payment,
		meta: {
			needLogin: true
		},
		component: resolve => require(["../views/exchangeCode.vue"], resolve)
	},
	{
		path: '/projectDetail',
		name: 'projectDetail',
		// component: projectDetail,
		component: resolve => require(["../views/projectDetail.vue"], resolve),
		redirect: '/function',
		children: [
			{
				path: '/code',
				name: 'code',
				// component: code
				component: resolve => require(["../components/code.vue"], resolve)
			},
			{
				path: '/issues',
				name: 'issues',
				// component: issues
				component: resolve => require(["../components/issues.vue"], resolve)
			},
			{
				path: '/pullRequests',
				name: 'pullRequests',
				// component: pullRequests
				component: resolve => require(["../components/pullRequests.vue"], resolve)
			},
			{
				path: '/function',
				name: 'functionPage',
				// component: functionPage
				component: resolve => require(["../components/function.vue"], resolve)
			},
			{
				path: '/projectContribution',
				name: 'projectContribution',
				// component: projectContribution
				component: resolve => require(["../components/projectContribution"], resolve)
			}
		]
	},
	{
		path: '/checkGrantSegment',
		name: 'checkGrantSegment',
		component: resolve => require(["../views/checkGrantSegment"], resolve)
	},
	{
		path: '/projectEdit',
		name: 'projectEdit',
		// component: projectEdit,
		component: resolve => require(["../views/projectEdit"], resolve),
		redirect: '/projectInformationEdit',
		children: [
			{
				path: '/projectVersion',
				name: 'projectVersion',
				// component: projectVersion,
				component: resolve => require(["../components/ProjectVersion"], resolve)
			},
			{
				path: '/projectEditVote',
				name: 'projectEditVote',
				// component: projectEditVote,
				component: resolve => require(["../components/projectEditVote"], resolve)
			},

			{
				path: '/projectInfoList',
				name: 'projectInfoList',
				// component: projectInfoList,
				component: resolve => require(["../components/ProjectInfoList"], resolve)
			},
			{
				path: '/projectInformationEdit',
				name: 'projectInformationEdit',
				// component: projectInformationEdit,
				component: resolve => require(["../components/ProjectInformationEdit"], resolve)
			}
		]
	},
	{
		path: '/user',
		name: 'user',
		// component: user,
		component: resolve => require(["../views/user.vue"], resolve),
		redirect: '/userHome',
		children: [
			{
				path: '/userHome',
				name: 'userHome',
				// component: userHome,
				component: resolve => require(["../components/userHome.vue"], resolve)
			},
			{
				path: '/userRepositories',
				name: 'userRepositories',
				// component: userRepositories,
				component: resolve => require(["../components/userRepositories.vue"], resolve)
			},
			{
				path: '/userProjects',
				name: 'userProjects',
				// component: userProjects,
				component: resolve => require(["../components/userProjects"], resolve)
			},
			{
				path: '/userPackages',
				name: 'userPackages',
				// component: userPackages,
				component: resolve => require(["../components/userPackages"], resolve)
			}
		]
	},
	{
		path: '/needLogin',
		name: 'needLogin',
		component: resolve => require(["../views/needLogin"], resolve)
	},
	{
		path: '/Login',
		name: 'Login',
		// component: login,
		component: resolve => require(["../views/login"], resolve)
	},
	{
		path: '/register',
		// component: signIn,
		component: resolve => require(["../views/signIn"], resolve)
	},
	{
		path:'/create',
		// component: createProject,
		component: resolve => require(["../components/createProject"], resolve)
	},
	{
		path: '/notifications',
		name: 'notifications',
		// component: notifications,
		component: resolve => require(["../views/notifications.vue"], resolve)
	},
	{
		path:'/createJoin',
		// component: createJoin,
		component: resolve => require(["../views/createJoin"], resolve),
		redirect: '/createJoin1',
		children: [
			{
				path: '/createJoin1',
				name: 'createJoin1',
				// component: createJoin1,
				meta: {
					needLogin: true
				},
				component: resolve => require(["../components/createJoin1"], resolve)
			},
			{
				path: '/createJoin2',
				name: 'createJoin2',
				// component: createJoin2,
				meta: {
					needLogin: true
				},
				component: resolve => require(["../components/createJoin2"], resolve)
			},
			{
				path: '/createJoin3',
				name: 'createJoin3',
				meta: {
					needLogin: true
				},
				// component: createJoin3,
				component: resolve => require(["../components/createJoin3"], resolve)
			}
			]

	},
	{
		path:'/checkJoin',
		// component: checkJoin,
		component: resolve => require(["../views/checkJoin"], resolve),
		redirect: '/checkJoinList',
		children: [
			{
				path: '/checkJoinList',
				name: 'checkJoinList',
				// component: checkJoinList,
				meta: {
					needLogin: true
				},
				component: resolve => require(["../components/checkJoinList"], resolve)
			},
			{
				path: '/checkJoinMember',
				name: 'checkJoinMember',
				// component: checkJoinMember,
				meta: {
					needLogin: true
				},
				component: resolve => require(["../components/checkJoinMember"], resolve)
			},
			{
				path: '/checkJoinInfo',
				name: 'checkJoinInfo',
				// component: checkJoinInfo
				meta: {
					needLogin: true
				},
				component: resolve => require(["../components/checkJoinInfo"], resolve)
			},
			{
				path: '/checkFurtherInfo',
				name: 'checkFurtherInfo',
				meta: {
					needLogin: true
				},
				// component: checkFurtherInfo,
				component: resolve => require(["../components/checkFurtherInfo"], resolve)
			},
			{
				path: '/checkFurtherList',
				name: 'checkFurtherList',
				meta: {
					needLogin: true
				},
				// component: checkFurtherList,
				component: resolve => require(["../components/checkFurtherList"], resolve)
			},
			{
				path: '/grantSegment',
				name: 'grantSegment',
				meta: {
					needLogin: true
				},
				component: resolve => require(["../components/grantSegment"], resolve)
			},
			{
				path: '/grantSegmentList',
				name: 'grantSegmentList',
				meta: {
					needLogin: true
				},
				component: resolve => require(["../components/grantSegmentList"], resolve)
			}
		]


	},
	{
		path: '/furtherInfo',
		name: 'projectFurtherInfo',
		// component: projectFurtherInfo,
		component: resolve => require(["../views/projectFurtherInfo"], resolve)
	},
	// {
	//   path: '/about',
	//   name: 'About',
	//   // route level code-splitting
	//   // this generates a separate chunk (about.[hash].js) for this route
	//   // which is lazy-loaded when the route is visited.
	//   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
	// }
]

const router = new VueRouter({
	routes
})

export default router
