export function getUserId () {
  return sessionStorage.getItem('userId')
}

export function setUserId (userId) {
  return sessionStorage.setItem('userId', userId)
}

export function removeUserId () {
  return sessionStorage.removeItem('userId')
}
