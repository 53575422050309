import fetch from '@/utils/fetch'

const pit = {
  state: {
  },

  mutations: {
  },

  getters: {
  },

  actions: {
    getAllFiles: async ({ state, dispatch, commit }, data) => {
      let url = `/pit/getAllFile?projectId=${data.projectId}&relPath=${data.relPath}&branch=${data.branch}`
      const res = await fetch.get(url)
      return res
    },
    grantSegment: async ({ state, dispatch, commit }, data) => {
      let url = `/pit/grantSegment`
      const res = await fetch.post(url, data)
      return res
    },
    getGrantSegment: async ({ state, dispatch, commit }, grantSegmentId) => {
      let url = `/pit/getGrantSegment?grantSegmentId=${grantSegmentId}`
      const res = await fetch.get(url)
      return res
    },
    getAllGrantSegment: async ({ state, dispatch, commit }, data) => {
      let url = `/pit/getAllGrantSegment?projectId=${data.projectId}&userId=${data.userId}&state=${data.state}`
      const res = await fetch.get(url)
      return res
    },
    agreeGrantSegment: async ({ state, dispatch, commit }, grantSegmentId) => {
      let url = `/pit/agreeGrantSegment?grantSegmentId=${grantSegmentId}`
      const res = await fetch.get(url)
      return res
    },
    rejectGrantSegment: async ({ state, dispatch, commit }, grantSegmentId) => {
      let url = `/pit/rejectGrantSegment?grantSegmentId=${grantSegmentId}`
      const res = await fetch.get(url)
      return res
    },
  }
}

export default pit
