import router from '../router'
import store from '../store'
import { getUserId } from '@/utils/auth'
import ViewUI from 'view-design';
// 路由权限控制
// 添加 meta:{
//   needLogin: true
// }
// 即可控制必须登录
router.beforeEach(async (to, from, next) => {
  ViewUI.LoadingBar.start();
  const hasUserId = getUserId()
  if (hasUserId) {
    store.commit('setLogin', hasUserId)
    next()
  }
  if (to.meta.needLogin) {
    if (hasUserId) next()
    else next({
      name: 'needLogin',
      query: {
        redirect: to.fullPath
      }
    })
  }
  else next()
})
router.afterEach(route => {
  ViewUI.LoadingBar.finish();
});