<template>
  <div class="container">
    <div class="section fixed bg1" style="height: 70vh">
      <div class="mask">
        <div style="width: 45rem; margin: 0 auto; line-height: 4rem">
          <span
            style="
              color: #e9ab01;
              font-size: 36px;
              font-weight: 600;
              display: block;
            "
            >A Platform for the Contribution-based Software Regime</span
          >
          <div style="font-size: 1.2rem;margin-bottom: -30px">
            Collaborate as Open-source, Benefit as Closed-source
          </div>
          <div style="font-size: 1.2rem">
            Value open source, pay for its worth. Let's make it fair and
            transparent.
          </div>
          <Input
            search
            id="input"
            enter-button
            placeholder="Search Projects..."
            @on-search="search"
          />
        </div>
      </div>
    </div>
    <div
      class="section"
      style="
        height: 35vh;
        position: relative;
        text-align: left;
        padding: 3vh;
        padding-left: 3vw;
      "
    >
      <h1 class="wow fadeInLeft header">
        Make your software <b>sustainable</b>
      </h1>
      <p class="wow fadeInLeft text" style="margin: 0 3vh 3vh; width: 45vw">
        contribution-based software ownership regime protects direct, reasonable
        profits as well as keeping open collaboration in software process.
      </p>
      <img
        style="
          position: absolute;
          width: 45vw;
          right: 2vw;
          top: 15vh;
          border-radius: 3vw;
        "
        src="../assets/home/home1.jpg"
      />
    </div>
    <div class="section fixed bg2" style="height: 105vh">
      <div class="mask">
        <div>
          <h1 class="header wow fadeInUp" style="text-align: left">
            What's <b>great</b> about<br />contribution-based software ownership
            regime
          </h1>
        </div>
        <div class="text" style="display: flex; font-weight: bold">
          <div class="advance_box wow fadeInUp" data-wow-delay="0.5s">
            <img src="../assets/home/icon1.jpg" /><br />incentives
          </div>
          <div class="advance_box wow fadeInUp" data-wow-delay="0.75s">
            <img src="../assets/home/icon2.jpg" /><br />transparent
          </div>
          <div class="advance_box wow fadeInUp" data-wow-delay="1s">
            <img src="../assets/home/icon3.jpg" /><br />trustable
          </div>
        </div>
      </div>
    </div>
    <div
      class="section"
      style="
        position: relative;
        height: 70vh;
        display: flex;
        flex-direction: row-reverse;
        padding: 3vh 3vw;
      "
    >
      <div
        class="wow fadeInRight header"
        style="width: 45vw; text-align: right; flex: auto"
      >
        <b>What can you do </b><br />with<br />
        <b style="font-size: 6vh"
          >contribution-based<br />software ownership regime?</b
        >
      </div>
      <div
        class="text"
        style="
          text-align: left;
          width: 49vw;
          height: 30 vh;
          position: absolute;
          top: -10vh;
          left: 0;
          background-color: #fff;
          padding: 2vw;
        "
      >
        <div class="todo_box wow fadeInLeft">
          <b>Contribute code</b><br />
          contribute to the platform and get paid accordingly
        </div>
        <div class="todo_box wow fadeInLeft">
          <b>learn to code</b><br />
          apply to the project you are interested in, and get the source code
          and the corresponding explanation.
        </div>
        <div class="todo_box wow fadeInLeft">
          <b>invest</b><br />
          you will become an investor in the project, and you can get a steady
          stream of income in the future.
        </div>
      </div>

      <img
        style="
          position: absolute;
          width: 20vw;
          right: 40vw;
          top: -10vh;
          border-radius: 35%;
        "
        src="../assets/home/home2.jpg"
      />
    </div>
    <div class="section fixed bg3">
      <div class="mask">
        <h1
          class="header wow fadeInUp"
          style="margin: auto 0 20vh; font-size: 6vh"
        >
          <b>How to use</b><br />
          the platform of contribution-based software ownership regime
        </h1>
      </div>
    </div>
    <div
      class="section"
      style="
        height: 55vh;
        padding: 3vh 5vw;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      "
    >
      <div
        class="text"
        style="width: 45vw; flex-direction: column; position: relative"
      >
        <img
          class="round"
          style="width: 15vw; position: absolute; top: -15vh; left: 15vw"
          src="../assets/home/home3-1.jpg"
        /><br />
        <br />
        <br />
        <b class="wow fadeInDown">program developer</b>
        <ul class="wow fadeInDown" style="text-align: left; padding-left: 5vw">
          <li>Issue an application to your program</li>
          <li>Get the corresponding source code</li>
          <li>Make the contribution you want</li>
          <li>Contribution completed to get Ownership shares</li>
          <li>Follow-up benefits based on Ownership shares</li>
        </ul>
      </div>
      <div
        class="text"
        style="width: 45vw; flex-direction: column; position: relative"
      >
        <img
          class="round"
          style="width: 15vw; position: absolute; top: -15vh; right: 15vw"
          src="../assets/home/home3-2.jpg"
        /><br />
        <br />
        <br />
        <b class="wow fadeInDown">investor</b>
        <ul class="wow fadeInDown" style="text-align: left; padding-left: 5vw">
          <li>Purchase executable or source code of interest</li>
          <li>Get ownershio shares based on the amount invested</li>
          <li>Follow-up benefits based on Ownership shares</li>
        </ul>
      </div>
    </div>
    <div class="footer">
      <img height="80%" src="../assets/logo.png" />
      <div>
        <h3>PLATFORM</h3>
        <a>Use cases</a><br />
        <a>Sign up</a><br />
        <a>Log in</a><br />
      </div>
      <div>
        <h3>JOIN</h3>
        <a>Create a Project</a><br />
        <a>Discover</a><br />
        <a>Become a Developer</a><br />
        <a>Become a Investor</a><br />
      </div>
      <div>
        <h3>COMMUNITY</h3>
        <a>Open Source</a><br />
        <a>Docs & Help</a><br />
      </div>
      <div>
        <h3>COMPANY</h3>
        <a>About</a><br />
        <a>Blog</a><br />
        <a>Privacy Policy</a><br />
        <a>Security Policy</a><br />
      </div>
    </div>
    <!-- <div> -->
    <!-- other content -->
    <!-- <div id="gitalk-container"></div> -->
  </div>
  <!-- </div> -->
</template>

<script>
import { WOW } from "wowjs";
import Gitalk from "gitalk";
import "gitalk/dist/gitalk.css";

//import * as Contract from "../abi/testCounter";
//import {MyCounter} from "../abi/testCounterClass";

export default {
  data() {
    return {};
  },
  methods: {
    
    renderGitalk() {
      const gitalk = new Gitalk({
        clientID: "41f9a9f5a1f9482533cc",
        clientSecret: "c17fe2d3431b8f52edbb5eed4a8fa327b9209231",
        repo: "Comments",
        owner: "zhangshuoxiao",
        admin: ["zhangshuoxiao"],
        id: "zhangshuoxiao / Comments",
        distractionFreeMode: false,
      });
      // gitalk.render("gitalk-container");
    },

    search(val) {
      this.$router.push("/projectList?input=" + val);
    },
  },
  mounted() {

    this.renderGitalk();

    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      var wow = new WOW({
        live: false,
      });
      wow.init();
    });

    // Contract.setCounter(1).then((data) => {
    //     Contract.getCounter().then((data) => {
    //         console.log(data)
    //     })
    // })

    // let counter = new MyCounter()
    // counter.getCounter().then((result)=>{
    //   console.log(result)
    // })
  },
};
</script>

<style>
.container {
  perspective: 1px;
  overflow: hidden;
}
.round {
  border-radius: 50%;
}
.section {
  width: 100vw;
  height: 70vh;
  background: white;
  color: rgb(233, 171, 1);
}
.fixed {
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
}
.bg1 {
  background-image: url("../assets/3.jpg");
}
.bg2 {
  background-image: url("../assets/bg2.jpg");
}
.bg3 {
  background-image: url("../assets/bg3.jpg");
}
.mask {
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.advance_box {
  padding: 3vh;
  height: 25vh;
  margin: 3vw;
  width: 15vw;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 2vw;
}
.todo_box {
  z-index: 1;
  width: 37vw;
  margin: 3vh;
}
.advance_box img {
  width: 4vw;
}
.header {
  font-size: 7vh;
}
.text {
  font-size: 3.5vh;
  color: rgb(195, 148, 0);
}
.footer {
  background-color: rgb(233, 171, 1);
  height: 150px;
  padding: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: left;
}
.footer div {
  margin: 0 2vw;
  padding: 0 2vw;
  border-left: #ffd893 0.15vw solid;
  flex-direction: column;
}
#lower {
  background-color: #ffd893;
  height: 8.8rem;
}
#input {
  line-height: 3rem;
  height: 3rem;
  margin-top: 1.5rem;
}
#input > .ivu-input {
  line-height: 3rem;
  height: 3rem;
  font-size: 18px;
  padding-left: 1rem;
}
.layout-footer-center {
  display: none;
}
</style>
